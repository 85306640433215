<template>
  <div>
    <!-- Header -->
    
    <!-- end Header -->

    <!-- Main Banner -->
    <div class="bg-center bg-no-repeat bg-cover lg:py-52 xl:py-60" style="background-image:url('/images/bg-thank.jpg')">
      <img src="/images/bg-thank.jpg" alt="" class="md:w-full lg:hidden">
    </div>
    <!-- end Main Banner -->
    <div class="py-10">
      <div class="container">
        <img src="/images/main-logo.png" alt="" class="mx-auto w-60">
        <div class="text-center">
          <h1
            class="pb-6 text-3xl font-bold text-center text-color-orange md:text-4xl"
          >
            Message Sending Successful!
          </h1>
          <p class="px-4 text-lg text-gray-800 md:px-0">
            Thank you for your kind interest in Udani Carpets Sdn Bhd. We will
            get back to you as soon as possible.
          </p>
          <router-link to="/" class="block w-48 mx-auto bg-gray-800 rounded-lg">
            <p class="block py-2 my-5 text-white">Back To Home</p>
          </router-link>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style>
</style>